import React from 'react'
import data from "./team.json"
import "./OurTeam.css"
import CardOurTeam from '../component/CardOurTeam'

export default function OurTeam() {
    return (
        <>
            <div className="O-Container">
                <div className="O-Head">
                    <div className="O-HeadName">
                    <h1>Meet Our Team</h1>
                    </div>
                    <div className="O-HeadDetail">

                        <p>



Welcome to the heart of Techara , where innovation thrives, and challenges transform into opportunities. Our diverse and skilled team is the backbone of our success, each member contributing their unique talents to drive technological excellence and client satisfaction</p>

                    </div>
                </div>
                <div className="O-CardContainer">
                    <div div className="O-Card" >
                        {
                            data.map((curElement, index) => {
                                // const { name, post } = curElement
                                return (
                                    <CardOurTeam imagepath={curElement.imagepath} key={index} name={curElement.name} post={curElement.post} Facebook={curElement.Facebook} Instagram={curElement.Instagram} Github={curElement.Github} X={curElement.X} />                                    
                                )
                            })
                        }
                    </div>
                </div>
            </div >
        </>
    )
}
