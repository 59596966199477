import React from 'react'

export default function CardOurTeam(props) {
    return (
        <>
            <div className="C-Container">
                <div className="Ca-img">
                    <img className="Ca-Image" src={props.imagepath} alt="" />
                </div>
                <div className="Ca-Detail">
                    <div className="Ca-Name">
                        <h5>{props.name}</h5>
                    </div>
                    <div className="Ca-Post">
                        <p>{props.post}</p>
                    </div>
                    {/* <div className="Ca-Sociallink">
                        <ul className="Ca-Social">
                            <li className='Ca-IconList'>
                                <div className="Ca-IconFront">
                                    <a href={props.Facebook}>
                                        <img className="Ca-SocialImg" src="images\icons8-facebook-48.png" alt="" height={40} width={40} />
                                    </a>
                                </div>
                            </li>
                            <li className='Ca-IconList '>
                                <div className="Ca-IconFront">
                                    <a href={props.Instagram}>
                                        <img className="Ca-SocialImg" src="images\icons8-instagram-48.png" alt="" height={40} width={40} />
                                    </a>
                                </div>
                            </li>
                            <li className='Ca-IconList '>
                                <div className="Ca-IconFront">
                                    <a href={props.Github}>
                                        <img className="Ca-SocialImg" src="images\icons8-linked-in-48.png" alt="" height={40} width={40} />
                                    </a>
                                </div>
                            </li>
                            <li className='Ca-IconList '>
                                <div className="Ca-IconFront">
                                    <a href={props.X}>
                                        <img className="Ca-SocialImg" src=" images\icons8-twitterx-48 (2).png" alt="" height={40} width={40} />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </>
    )
}
