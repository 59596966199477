import React from 'react'
import './Footer.css'

export default function Footer() {
    return (
        <>
            <footer className='footer-section'>
                <div className='F-upperDetails'>
                    <div className='F-uprow'>
                        <div className='F-CompText'>
                            <div className='F-logo'><img src="images\footer logo.png" alt="" /></div>
                            <div className='F-logoText'>
                                <p>Techara Infoway: Pioneering Tomorrow's Tech Solutions
                                    Embark on a technological journey with Techara Infoway, where innovation meets excellence in crafting futuristic solutions. As a global-scale pioneer, we specialize in delivering state-of-the-art technology solutions that redefine industry standards.</p>
                            </div>
                        </div>
                        <div className='F-Address'>
                            <div className='F-InnerAddress'>
                                <div className='F-Nation'>
                                    <div className='F-Flag'>
                                        <img src="https://www.yudiz.com/wp-content/themes/yudiz/images/footer-country-flag.svg" alt="" />
                                    </div>
                                    <strong className='F-Name'>INDIA-HQ</strong>
                                </div>
                                <div className='F-AddressLine'>
                                    <p>
                                        6th Floor, Alpha Plus 608,
                                        <br />
                                        Raiya Telephone Exchange,
                                        <br />
                                        Rajkot, Gujarat - 360005
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='F-ContectDetail'>
                            <div className='F-ContectDetailInner'>
                                <p className='F-ContectDetailHead'><strong>Starts A Conversation</strong></p>
                                <div className='F-ContectDetailBody'>
                                    <ul className='F-ContectUl'>
                                        <li><strong>Email: contact@techarainfoway.com</strong><a href=""></a></li>
                                        <li><strong>Contact: (+91) 9106572154</strong><a href=""></a></li>

                                        {/* <li><strong>Call For Sales:</strong><a href=""></a></li>
                                        <li><strong>Call For HR:</strong><a href=""></a></li>
                                        <li><strong>Skype:</strong><a href=""></a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='F-downrow'>
                        <div className='F-Left'>
                            <div className='F-LeftIn'>
                                <div className="F-LinkBox">

                                    <div className="F-AboutLink">
                                        <p>
                                            <strong>
                                                ABOUT US
                                            </strong>
                                        </p>
                                        <div className='F-LinkArea'>
                                            <ul className='F-LinkAreaul'>
                                                <li><a href="">Our Company</a></li>
                                                <li><a href="">Career</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="F-SolutionLink">
                                        <p>
                                            <strong>
                                                SOLUTIONS
                                            </strong>
                                        </p>
                                        <div className='F-LinkArea'>
                                            <ul>
                                                <li><a href="">Website Development</a></li>
                                                <li><a href="">Graphic Designing</a></li>
                                                <li><a href="">Mobile Application Development</a></li>
                                                

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='F-Right'>

                        </div>
                    </div>
                    <div></div>
                </div>
                <div className='F-lowerDetails'>
                    <div className='F-partner'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className='F-pattern'>
                        <small>@ 2024 Techara Infotech Ltd. All Right Reserved</small>
                    </div>
                    <div className='F-social'>
                        <div className='F-socialText'>
                            <ul className='F-ul'>
                                <li>Brand Guidelines</li>
                                <li className='F-li-mid'>Privacy Policy</li>
                                <li>Sitemap</li>
                            </ul>
                        </div>
                        <div className='F-socialIcon'>
                            <ul className='F-IconUList'>
                                <li className='F-IconList'><img src="images\icons8-facebook-48.png" alt="" /></li>
                                <li className='F-IconList'><img src="images\icons8-linked-in-48.png" alt="" /></li>
                                <li className='F-IconList F-FirstList'><img src="images\icons8-twitterx-50.png" alt="" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
