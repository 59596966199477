// import logo from './logo.svg';
// import './App.css';
import Navbar from './component/Navbar';
import Home from './pages/Home';
import Footer from "./component/Footer"
import Service from './pages/Service';
import Contact from './pages/Contact';
import OurTeam from './pages/OurTeam';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/About';
function App() {
  return (
    <>
      <Router>
         <Navbar service1="web-development" service2="graphic-designing" service3="android-development" />
         <Routes>
          <Route path='/' element={<Home></Home>} />
          <Route path='service/:service_name' element={<Service/>} />
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/ourteam' element={<OurTeam/>}/>
        </Routes>
        <Footer /> 
      </Router>
    </>
  );
}

export default App;
