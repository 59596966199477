import React from 'react'
import CardRiew from './CardRiew'

export default function Review() {
    return (
        <>
            <div className="review-head" data-aos="fade-up">
                <h1>TESTIMONIALS</h1>
                <h2>WHAT OUR CLIENTS SAY ABOUT US</h2>

            </div>
            <div className="container-flex" data-aos="zoom-in">

                <CardRiew review="Working with the team at Techara Web Development has been an absolute pleasure. From the initial consultation to the final launch of our website, they demonstrated a level of professionalism and expertise that exceeded our expectations"
                    img="https://img.freepik.com/free-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-859.jpg?w=740&t=st=1711184039~exp=1711184639~hmac=4a5c5bf9f1f7743639a5f168bc7d58a4dd13e4773d3b97b0d36ccc7f3dd067ba"
                    name="Kishan Patel"
                    //extraname="HOD"
                />
                <CardRiew review="Working with the team at Techara Application Development has been an incredible experience from start to finish. Their dedication to understanding our project needs and delivering outstanding results has truly set them apart."
                    img="https://img.freepik.com/free-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-859.jpg?w=740&t=st=1711184039~exp=1711184639~hmac=4a5c5bf9f1f7743639a5f168bc7d58a4dd13e4773d3b97b0d36ccc7f3dd067ba"
                    name="Yash Vekariya"
                    //extraname="HOD"
                />
                <CardRiew review="Working with the talented team at Techara Graphics Design has been an absolute pleasure. Their creativity, attention to detail, and dedication to bringing our vision to life have made all the difference in our project."
                    img="https://img.freepik.com/free-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-859.jpg?w=740&t=st=1711184039~exp=1711184639~hmac=4a5c5bf9f1f7743639a5f168bc7d58a4dd13e4773d3b97b0d36ccc7f3dd067ba"
                    name="Pragnesh Borad"
                    //extraname="HOD"
                />
                <CardRiew review="Working with the team at Techara Mobile App Development has been an incredible experience from start to finish. Their expertise, professionalism, and commitment to delivering a top-notch product have truly exceeded our expectations."
                    img="https://img.freepik.com/free-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-859.jpg?w=740&t=st=1711184039~exp=1711184639~hmac=4a5c5bf9f1f7743639a5f168bc7d58a4dd13e4773d3b97b0d36ccc7f3dd067ba"
                    name="Charchil Patel"
                    //extraname="HOD"
                />
            </div>
        </>
    )
}   
