import React from 'react'
import CardServices from './CardServices'

export default function Services() {
    return (
        <>
            <div className="home-services-head" data-aos="fade-up">
                <h1>OUR SERVICES</h1>
                <h2>WE PROVIDE EXCLUSIVE SERVICES FOR OUR CLIENTS</h2>

            </div>
            <div className="home-services">
                <CardServices/>
            </div>
        </>
    )
}
