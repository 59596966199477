import React from 'react'

export default function CardRiew(props) {
    return (
        <figure className="T">
            <blockquote>{props.review}
                <div className="arrow"></div>
            </blockquote>
            <img alt="" src={props.img} />
            <div className="author">
                <h5>{props.name}<span> {props.extraname}</span></h5>
            </div>
        </figure>
    )
}
