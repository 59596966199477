import React from 'react'
import AOS from 'aos';
import './Home.css'
import 'aos/dist/aos.css';
import Herocontent from '../component/Herocontent'
import Clientcontent from '../component/Clientcontent'
import CompanyExp from '../component/CompanyExp';
import Services from '../component/Services';
import Team from '../component/Team';
import Review from '../component/Review';
export default function Home() {
    AOS.init();

    return (
        <>
            <div className='home-container'>
                <Herocontent />
                {/* <Clientcontent /> */}
                <Services />
                <CompanyExp />
                {/* <Team /> */}
                <Review/>
            </div>
        </>
    )
}
