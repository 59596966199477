import React from 'react'
import Typewriter from "typewriter-effect";

export default function Herocontent() {
  return (
    <>
      <div className="home-head">
        <div className="home-head-left" data-aos="flip-down">
          <h1>TECHARAINFOWAY,</h1>
          <h1> A PROFESSIONAL</h1>
          <div className="type-word">
            <Typewriter
              className="type-word"
              options={{
                strings: [
                  "APPLICATION DEVELOPMENT",
                  "WEB DEVELOPMENT",
                  "GRAPHIC DESIGNING",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        </div>
        <div className="home-head-right" data-aos="fade-up-left">
          <img src="\images\Hari Web Infotech Pvt Ltd (1).gif" alt="" />
        </div>
      </div>
    </>
  )
}
