import React from 'react'
import "./AboutImage.css";

export default function AboutImage() {
    return (
        <>
            <h2 className="I-h2">GALLERY</h2>
            <div className="I-row">
                <div className="I-column">
                    <img alt="about_gallery"
                        src="\images\gallery\pic1.jpg"
                        onclick="openModal();currentSlide(1)"
                        className="I-hover-shadow I-cursor"
                    />
                </div>
                <div className="I-column">
                    <img alt="about_gallery"
                        src="\images\gallery\pic2.jpg"
                        onclick="openModal();currentSlide(2)"
                        className="I-hover-shadow I-cursor"
                    />
                </div>
                <div className="I-column">
                    <img alt="about_gallery"
                        src="\images\gallery\pic3.jpg"
                        onclick="openModal();currentSlide(3)"
                        className="I-hover-shadow I-cursor"
                    />
                </div>
                <div className="I-column">
                    <img alt="about_gallery"
                        src="\images\gallery\pic4.jpg"
                        onclick="openModal();currentSlide(4)"
                        className="I-hover-shadow I-cursor"
                    />
                </div>
            </div>
        </>
    )
}
