import React from 'react'
import { Link } from 'react-router-dom'
export default function CardItemServices(props) {
    return (
        <>
            <div className="home-services-container">
                <div className="home-services-name">
                    <div className="home-services-gif">
                        <img src={props.img} alt="" />
                    </div>
                    <div className="home-services-text">
                        <h2>{props.title}</h2>
                    </div>
                </div>
                <div className="home-services-content">
                    <p>
                        {props.discription}
                    </p>
                    <Link to={`/service/${props.title.replace(" ", "-").toLowerCase()}`}>Read more<i className="ri-arrow-right-line"></i></Link>
                </div>
            </div>
        </>
    )
}
