import React from 'react'
import AboutImage from '../component/AboutImage'
import './About.css'
export default function About() {
    return (
        <>
            <div className="A-container">
                <div className="A-Text">
                <div className="A-TextInner">

<h1 className="A-h1">Empower Your Business <br /> Transformation with Techara Infoway!</h1>

<p>Techara Infoway stands as a leading digital transformation catalyst, offering a comprehensive suite of solutions encompassing web development, mobile applications, game development, and cutting-edge blockchain solutions tailored to elevate your business to new heights.</p>

<p>As an ISO 9001:2015 certified IT development company, Techara Infoway is your trusted partner in the realm of digital transformation and technology services. Our commitment to delivering excellence is evident from ideation to execution, where we consistently provide robust, forward-thinking, and high-quality solutions to meet your unique business needs.</p>

<p>From conceptualization to implementation, Techara Infoway provides end-to-end IT services, delivering foresighted solutions that give your business a competitive edge.</p>

<p>Partner with us, and rest assured, you'll know the difference!</p>

</div>

                </div>
                <div className="A-Carousel">
                    <AboutImage/>
                </div>
            </div>
        </>
    )
}
