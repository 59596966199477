import React, { useState } from 'react'
import "./Service.css";
import { useParams } from 'react-router-dom';
import data from "./service.json"
import { Link } from 'react-router-dom';
export default function Service(props) {

  const { service_name } = useParams();
  console.log(data.filter((data) => data.service === service_name));
  const singleData = data.filter((data) => data.service === service_name);
  
  return (
    <>
      <div className="web-container">
        <div className="web-1" >
          <div className="web-left">
            <div>
              <img
                src="https://cyferinfotech.com/wp-content/uploads/2023/06/3343973-1536x1536.png"
                alt="Cinque Terre"
                width="600"
                height="500"
              />
            </div>
          </div>
          <div className="web-right">
            <div className="web-right-inner">
              {/* <p id="p1">TECHARAINFOTECH</p>
              <p id="p2">OUR SERVICES</p> */}
              <p id="p1">TECHARAINFOWAY ,</p>
              <p id="p2">{service_name.replace("-", " ")}</p>
              {
                singleData.map((element, index) => {
                  return (
                    <p id="p3" key={index}>
                      {element.discription}
                    </p>
                  )
                })
              }

              <div className="web-button">
                <Link to="/contact"><button className="web-btn">Lets Connect !</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
