import React from 'react'
import CardItemServices from './CardItemServices'

export default function CardServices() {
    return (
        <>
            <div data-aos="flip-left" className="home-services-1" >
                <CardItemServices
                    img="\images\coding-unscreen.gif"
                    title="Web Development"
                    discription="Techarainfoway allows workings on the platform of custom website development or pre-designed template-based application.
                     Custom website development needs a little more time and development work hence cost little more expensive or 
                     more against to using pre-developed template based extract which is generally free or cost considerably lower,
                      but lack compliance and required abilities. "
                />
            </div>
            <div data-aos="flip-up" className="home-services-2" >
                <CardItemServices
                    img="\images\graphic.gif"
                    title="Graphic Designing"
                    discription="Techarainfoway is a leading website development company incorporating adroit web 
                    developers who leverage their expertise to deliver impeccable solutions. We create e-commerce 
                    portals, web applications, and social networks with robust user experiences within deadlines. 
                    Our best-in-class functionality and ability to develop tailored solutions makes us an ideal web
                     development partner."
                />
            </div>
            <div data-aos="flip-right" className="home-services-3" >
                <CardItemServices
                    img="\images\coding-unscreen.gif"
                    title="Mobile Application Development"
                    discription="Welcome to the Android Development at Techara Infoway, where we harness the power of 
                    innovation to create exceptional mobile experiences. Our seasoned team of Android developers is 
                    dedicated to crafting cutting-edge solutions that resonate with the dynamic needs of today's mobile 
                    landscape."
                />
            </div>
            
        </>
    )
}