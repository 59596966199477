import React from 'react'

export default function CompanyExp() {
    return (
        <>
            <div data-aos="zoom-in" className="home-experience">
                <div className="home-experience-head">
                    <p>YEARS EXPERIENCE</p>
                    <h1>4+</h1>
                </div>
                <div className="home-experience-middle"></div>
                <div className="home-experience-head">
                    <p>EMPLOYEES</p>
                    <h1>12</h1>
                </div>
                <div className="home-experience-middle"></div>
                <div className="home-experience-head">
                    <p>REPEATED CLIENTS</p>
                    <h1>65%</h1>
                </div>
            </div>
        </>
    )
}
