import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
export default function Navbar(props) {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light ps-lg-5 pe-lg-5 ps-sm-1 pe-sm-1">
                <div className="container-fluid">
                    <Link className="navbar-brand " to="/">
                        <img src="images\logo_page-0004-removebg-preview.png" alt="" width="100" height="90" className="d-inline-block align-text-top" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <div className="d-flex">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-lg-3 gap-xl-5 gap-sm-0">
                                <li className="nav-item">
                                    <Link className="nav-link " aria-current="page" to="/ourteam">Our Team</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="/service" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="dropdown-item" to={`/service/${props.service1}`}>{props.service1.replace("-"," ")}</Link></li>
                                        <li><Link className="dropdown-item" to={`/service/${props.service2}`}>{props.service2.replace("-"," ")}</Link></li>
                                        <li><Link className="dropdown-item" to={`/service/${props.service3}`}>{props.service3.replace("-"," ")}</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">Contact Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about">About Us</Link>
                                </li>
                                <Link to="/contact"><button className="btn btn-primary rounded-pill" type="submit">Business Inquiry</button></Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
